@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
button,
input,
textarea,
select,
div,
p,
a {
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
}

:root.dark {
  color-scheme: dark;
}

.light {
  --background: 0 0% 100%;
  --foreground: 0 0% 0%;

  --system-gray-50: 0 0% 98%;
  --system-gray-100: 0 0% 96%;
  --system-gray-200: 0 0% 90%;
  --system-gray-300: 0 0% 83%;
  --system-gray-400: 0 0% 64%;
  --system-gray-500: 0 0% 45%;
  --system-gray-600: 0 0% 32%;
  --system-gray-700: 0 0% 25%;
  --system-gray-800: 0 0% 15%;
  --system-gray-900: 0 0% 9%;
  --system-gray-950: 0 0% 4%;

  --brand-25: 0 73% 97%;
  --brand-50: 0 69% 95%;
  --brand-100: 0 73% 90%;
  --brand-200: 0 70% 79%;
  --brand-300: 0 71% 69%;
  --brand-400: 0 71% 58%;
  --brand-500: 0 71% 48%;
  --brand-600: 0 71% 38%;
  --brand-700: 0 70% 29%;
  --brand-800: 0 71% 19%;
  --brand-900: 0 73% 10%;
  --brand-950: 0 69% 5%;

  --brandGreen-50: 137 100% 93%;
  --brandGreen-100: 136 100% 87%;
  --brandGreen-200: 137 100% 73%;
  --brandGreen-300: 137 100% 60%;
  --brandGreen-400: 137 100% 47%;
  --brandGreen-500: 137 100% 34%;
  --brandGreen-600: 137 100% 27%;
  --brandGreen-700: 137 100% 20%;
  --brandGreen-800: 137 100% 13%;
  --brandGreen-900: 137 100% 7%;
  --brandGreen-950: 136 100% 3%;

  --brandBlue-50: 202 87% 94%;
  --brandBlue-100: 203 86% 89%;
  --brandBlue-200: 203 86% 78%;
  --brandBlue-300: 203 86% 66%;
  --brandBlue-400: 203 87% 55%;
  --brandBlue-500: 203 87% 44%;
  --brandBlue-600: 203 87% 35%;
  --brandBlue-700: 203 86% 26%;
  --brandBlue-800: 203 87% 18%;
  --brandBlue-900: 203 87% 9%;
  --brandBlue-950: 203 90% 4%;

  ::selection {
    background: theme("colors.brandBlue.100");
  }
}

.dark {
  --background: 0 0% 0%;
  --foreground: 0 0% 100%;

  --system-gray-50: 0 0% 4%;
  --system-gray-100: 0 0% 9%;
  --system-gray-200: 0 0% 15%;
  --system-gray-300: 0 0% 25%;
  --system-gray-400: 0 0% 32%;
  --system-gray-500: 0 0% 45%;
  --system-gray-600: 0 0% 64%;
  --system-gray-700: 0 0% 83%;
  --system-gray-800: 0 0% 90%;
  --system-gray-900: 0 0% 96%;
  --system-gray-950: 0 0% 98%;

  --brand-25: 0 69% 2%;
  --brand-50: 0 69% 5%;
  --brand-100: 0 73% 10%;
  --brand-200: 0 71% 19%;
  --brand-300: 0 70% 29%;
  --brand-400: 0 71% 38%;
  --brand-500: 0 71% 48%;
  --brand-600: 0 71% 58%;
  --brand-700: 0 71% 69%;
  --brand-800: 0 70% 79%;
  --brand-900: 0 73% 90%;
  --brand-950: 0 69% 95%;

  --brandGreen-50: 136 100% 3%;
  --brandGreen-100: 137 100% 7%;
  --brandGreen-200: 137 100% 13%;
  --brandGreen-300: 137 100% 20%;
  --brandGreen-400: 137 100% 27%;
  --brandGreen-500: 137 100% 34%;
  --brandGreen-600: 137 100% 47%;
  --brandGreen-700: 137 100% 60%;
  --brandGreen-800: 137 100% 73%;
  --brandGreen-900: 136 100% 87%;
  --brandGreen-950: 137 100% 93%;

  --brandBlue-50: 203 90% 4%;
  --brandBlue-100: 203 87% 9%;
  --brandBlue-200: 203 87% 18%;
  --brandBlue-300: 203 86% 26%;
  --brandBlue-400: 203 87% 35%;
  --brandBlue-500: 203 87% 44%;
  --brandBlue-600: 203 87% 55%;
  --brandBlue-700: 203 86% 66%;
  --brandBlue-800: 203 86% 78%;
  --brandBlue-900: 203 86% 89%;
  --brandBlue-950: 202 87% 94%;

  ::selection {
    background: theme("colors.brandBlue.200");
  }
}

@layer base {
  * {
    @apply border-systemGray-200;
  }
  body {
    @apply text-foreground bg-background;
  }
}
