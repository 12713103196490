@keyframes slide {
  0% {
    background-position: -100% 100%, 0 0;
  }
  30% {
    background-position: 200% 0, 0 0;
  }
  100% {
    background-position: 200% 0, 0 0;
  }
}

.bg-shine {
  --shine-color: rgb(255, 255, 255, 0.5);
  --delay: 0ms;
  background-image: linear-gradient(45deg, transparent 20%, transparent 45%, var(--shine-color) 50%, var(--shine-color) 55%, transparent 65%, transparent 100%);
  background-repeat: no-repeat;
  background-position: -100% 0;
  background-size: 250% 250%;
  animation: slide 5000ms linear infinite;
  animation-delay: var(--delay);
}
